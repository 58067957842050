import React from "react";
import PropTypes from "prop-types";

class TabsPanel extends React.Component {
    get tabList() {
        return (
            <ul className="nav nav-tabs text-center myTabs" role="tablist">
                {this.props.tabs.map(tab => {
                    const classList = (tab.default) ? 'nav-link active' : 'nav-link'
                    return (
                        <li key={`tab-list-${tab.id}`} className="nav-item" role="presentation">
                            <a className={classList} href={`#tab-${tab.id}`} aria-controls={`#tab-${tab.id}`}
                               role="tab">{tab.title}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }

    get tabContent() {
        return (
            <div className="tab-content text-md-left">
                {
                    this.props.tabs.map(tab => {
                        const classList = (tab.default) ? 'tab-pane fade active show' : 'tab-pane fade'
                        return (
                            <div key={`tab-content-${tab.id}`} className={classList} role="tabpanel"
                                 id={`tab-${tab.id}`}>
                                <p className="lead">{tab.description.description}</p>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <section className="text-center well section-border">
                <div className="container">
                    <h1 className="font-weight-bold text-center">{this.props.title}</h1>
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-xl-offset-1">
                            <div className="tabs-custom tabs-vertical tabs-corporate" id="tabs-1">
                                {this.tabList}
                                {this.tabContent}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

TabsPanel.propTypes = {
    title: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired
}

export default TabsPanel