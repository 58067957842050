import React from 'react'
import PropTypes from 'prop-types'
import Card from '../card/card'

class CardsGrid extends React.Component {
  get renderCards() {
    return (
      <section className='text-center text-md-left well well-sm section-border'>
        <div className='container'>
          <div className='row margin-1 flow-offset-3'>
            {
              this.props.cards.map(card => {
                return (
                  <div key={card.id} className='col-md-6'>
                    <Card key={card.id} {...card} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }

  render() {
    return this.renderCards
  }
}

CardsGrid.propTypes = {
  cards: PropTypes.array,
}

export default CardsGrid