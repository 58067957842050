import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import {Link} from "gatsby";

const activeStyle = {
    color: 'var(--primary-color)',
}

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction(navbar, sticky) {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}

class ProjectNavigation extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    renderLinks() {
        return this.props.links.map(link => {
            return <li className={'project-navigation-item'} key={link.id}>
                <Link activeStyle={activeStyle} className={'project-navigation-link'} title={link.title} to={link.link}>{link.title}</Link>
            </li>
        })
    }

    componentDidMount() {
        // Get the navbar
        const navbar = this.myRef.current;

        // Get the offset position of the navbar
        const sticky = navbar.offsetTop;

        // When the user scrolls the page, execute myFunction
        window.onscroll = function () {
            myFunction(navbar, sticky)
        };
    }

    render() {
        return (
            <nav ref={this.myRef} id={'project-navigation'} className={'project-navigation'}>
                <ul className={'project-navigation-list container'}>
                    {this.renderLinks()}
                </ul>
            </nav>
        )
    }
}

ProjectNavigation.propTypes = {
    links: PropTypes.array,
}

export default ProjectNavigation