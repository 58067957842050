import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import { Link } from 'gatsby'

class Card extends React.Component {
  get renderCTA() {
    return (this.props.ctaLink) ?
      <div>
        <Link className='button button-primary button-xs' to={this.props.ctaLink}>{this.props.ctaLabel}</Link>
      </div> :
      null
  }

  render() {
    console.log(this.props)
    return (
      <div className='fact-card box-sm box-skin-1 bg-lighter box-skin-left-offset-negative'>
        <div className='box__left box-md-inset-1'>
          <img className={'icon icon-md icon-primary line-height-1'} src={this.props.icon.file.url}
               alt={this.props.title} />
        </div>
        <div className='box__body box__middle'>
          <div className='content-wrap'>
            <div>
              <h5>{this.props.title}</h5>
              <p>
                {this.props.content.content}
              </p>
            </div>
            {this.renderCTA}
          </div>
        </div>
      </div>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  icon: PropTypes.object,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
}

export default Card