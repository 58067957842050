import {renderRichText} from 'gatsby-source-contentful/rich-text'

export const mapGraphQLToComponent = (component, data) => {
    switch (component) {
        case 'well':
            return mapWell(data);
        case 'counter':
            return mapCounter(data);
        case 'subscription':
            return mapSubscription(data);
        default:
            return null;
    }
}

const mapCounter = (data) => {
    return {
        ...data
    }
}

const mapWell = (data) => {
    const response = {
        ...data,
    }

    response.extraClass = '';

    response.lead = (data.content) ? renderRichText(data.content) : null;
    response.extraClass = data.color === 'Dark' ? response.extraClass + ' bg-dark' : response.extraClass;
    response.extraClass = data.color === 'Gray' ? response.extraClass + ' bg-lighter' : response.extraClass;
    response.extraClass = data.textCentered === true ? response.extraClass + ' text-center' : response.extraClass;
    response.variant = data.numberOfColumns === 'Two' ? 'two-columns' : 'default';
    response.cta = data.ctaLink ? {link: data.ctaLink, label: data.ctaLabel} : null;
    response.image = data.image ? {src: data.image.file.url, alt: data.title} : null;

    return response;
}

const mapSubscription = (data) => {
    return {
        ...data,
        lead: data.lead.lead,
        backgroundImage: data.backgroundImage.file.url
    };
}