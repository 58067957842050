import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

class MemberBio extends React.Component {
  render() {
    console.log(this.props)
    return <section className="text-center text-lg-left well well-sm">
      <div className="container">
        <article className="team-member row row-30 flex-md-row-reverse">
          <div className="col-md-12">
            <br/>
            <h1>{this.props.member.fullName}</h1>
            <p className="small text-light-clr text-uppercase">{this.props.member.position}</p>
            <p>
              {renderRichText(this.props.member.bio)}
            </p>

          </div>
        </article>
      </div>
    </section>
  }
}

export default MemberBio