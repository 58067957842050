import React from 'react'
import PropTypes from 'prop-types'
import ProjectNavigation from './navigation/navigation'
import ProjectAbout from './about/about'
import ProjectDevelopmentDetails from './development-details/development-details'
import ProjectEligibility from './eligibility/eligibility'
import ProjectParticipation from './participation/participation'
import ProjectLenders from './lenders/lenders'
import ProjectInvestments from './investments/investments'

import './style.css'
import Quote from "../quote/quote";
import Form from "../form/form";

class Project extends React.Component {
    renderSections() {
        return this.props.sections.map(section => {
            if (section.internal) {
                switch (section.internal.type) {
                    case 'ContentfulProjectNavigation':
                        return <ProjectNavigation key={section.id} {...section} />
                    case 'ContentfulProjectAbout':
                        return <ProjectAbout key={section.id} {...section} />
                    case 'ContentfulProjectDevelopmentDetails':
                        return <ProjectDevelopmentDetails key={section.id} {...section} />
                    case 'ContentfulProjectEligibility':
                        return <ProjectEligibility key={section.id} {...section} />
                    case 'ContentfulProjectParticipation':
                        return <ProjectParticipation key={section.id} {...section} />
                    case 'ContentfulProjectLenders':
                        return <ProjectLenders key={section.id} {...section} />
                    case 'ContentfulProjectInvestments':
                        return <ProjectInvestments key={section.id} {...section} />
                    case 'ContentfulQuote':
                        return <Quote key={section.id} {...section} />
                    default:
                        console.log('Missing: ', section.internal.type)
                        return null
                }
            }
            return null
        })
    }

    render() {
        return (
            <>
                {this.renderSections()}
                <Form form={'Request More Info'} projectName={this.props.projectName}/>
            </>
        )
    }
}

Project.propTypes = {
    sections: PropTypes.array,
    projectName: PropTypes.string.isRequired
}

export default Project