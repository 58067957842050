import React from 'react'
import PropTypes from 'prop-types'
import Member from '../member/member'

class Leadership extends React.Component {
  get renderTopLeadership() {
    return this.props.leadership.map(member => {
      return (
        <div key={member.id} className='col-12 col-md-12 col-lg-4 col-inset-2'>
          <Member {...member} variant={'top'} />
        </div>
      )
    })
  }

  get renderBoard() {
    return this.props.boardMembers.map(member => {
      return (
        <div className='col-6 col-lg-4' key={member.id}>
          <Member {...member} variant={'secondary'} />
        </div>
      )
    })
  }

  render() {
    return (
      <section className='well well-sm bg-lighter relative text-center'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-xl-offset-1'>
              <h1 className='font-weight-bold'>{this.props.title}</h1>
              <p className='lead'>{this.props.leadText.leadText}</p>
            </div>
          </div>
          <div className='row justify-content-center'>
            {this.renderTopLeadership}
          </div>
          <hr />
          <div className='row row-50 text-center'>
            {this.renderBoard}
          </div>
        </div>
      </section>
    )
  }
}

Leadership.propTypes =
  {
    title: PropTypes.string.isRequired,
    leadText: PropTypes.object.isRequired,
    leadership: PropTypes.array,
    boardMembers: PropTypes.array,
  }

export default Leadership