import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

class ProjectDevelopmentDetails extends React.Component {
    renderImages(renderingsAndFloorplan) {
        return renderingsAndFloorplan.map(image => {
            return <figure key={image.id} className={'project-development-details-gallery'}>
                <img src={image.file.url} alt={image.description}/>
                <figcaption>RENDERINGS & FLOORPLAN <span>{image.description}</span></figcaption>
            </figure>
        })
    }

    renderSpecs(model) {
        return (
            <>
                <table>
                    <tbody>
                    <tr>
                        <th>Bedrooms</th>
                        <th>Baths</th>
                    </tr>
                    <tr>
                        <td>{model.bedrooms}</td>
                        <td>{model.baths}</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr>
                        <th>Garage</th>
                        <th>Sqft Area</th>
                    </tr>
                    <tr>
                        <td>{model.garage}</td>
                        <td>{model.sqftArea.toLocaleString()}</td>
                    </tr>
                    </tbody>
                </table>
            </>
        )
    }

    renderModels() {
        return this.props.models.map(model => {
            return (
                <article key={model.id} className={'project-development-details-model'}>
                    <div className={'container'}>
                        {this.renderImages(model.renderingsAndFloorplan)}
                        <h1>{model.name}</h1>
                        {this.renderSpecs(model)}
                    </div>
                </article>
            )
        });
    }

    render() {
        return (
            <section id={'details'} className={'project-section project-development-details'}>
                <div className={'container project-development-details-header'}>
                    <h2 className="project-headline">{this.props.title}</h2>
                    <dl className={'fields-grid'}>
                        <dt className={'fields-grid-field'}>Neighborhood</dt>
                        <dd className={'fields-grid-field-value'}>{this.props.neighborhood}</dd>
                        <dt className={'fields-grid-field'}>Property Type</dt>
                        <dd className={'fields-grid-field-value'}>{this.props.propertType}</dd>
                        <dt className={'fields-grid-field'}>Property Size</dt>
                        <dd className={'fields-grid-field-value'}>{this.props.propertySize}</dd>
                        <dt className={'fields-grid-field'}>Appliances and features</dt>
                        <dd className={'fields-grid-field-value'}>{this.props.appliancesAndFeatures}</dd>
                    </dl>
                </div>
                {this.renderModels()}
            </section>
        )
    }
}

ProjectDevelopmentDetails.propTypes = {
    title: PropTypes.string,
    neighborhood: PropTypes.string,
    propertType: PropTypes.string,
    propertySize: PropTypes.string,
    appliancesAndFeatures: PropTypes.string,
    models: PropTypes.array
}

export default ProjectDevelopmentDetails