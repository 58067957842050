import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '../snackbar/snackbar'
import ThankYou from '../thankYou/thankYou'

const FORM_NAME = 'Subscription'


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class Subscription extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackbarIcon: 'loading',
      snackbarMessage: 'Sending',
      snackbarActive: false,
      formSent: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.sendingForm()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.formSent())
      .catch(error => alert(error))
  }

  sendingForm() {
    this.setState({
      ...this.state,
      snackbarActive: true,
    })
  }

  formSent() {
    this.setState({
      ...this.state,
      snackbarIcon: 'ok',
      snackbarMessage: 'Successfully sent!',
    })
    setTimeout(() => {
      this.setState({
        ...this.state,
        snackbarActive: false,
      })
    }, 3000)

    this.setState({ ...this.state, formSent: true })
  }

  render() {
    return (
      <section className='well well-sm well-inset-3 bg-image context-dark text-center'
               style={{ backgroundImage: `url(${this.props.backgroundImage}?w=1920&fm=webp&q=80)` }}>
        {!this.state.formSent ?
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8'>
                <h1 className='font-weight-bold'>{this.props.title}</h1>
                <p className='lead'>{this.props.lead}</p>
                <form className='rd-mailform1 subscribe-form margin-1' data-form-output='form-output-global'
                      onSubmit={this.handleSubmit} name={FORM_NAME}
                      data-form-type='forms' method='post' data-netlify='true' data-netlify-honeypot='bot-field'>
                  <p hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name='bot-field' onChange={this.handleChange} />
                    </label>
                  </p>
                  <div className='form-wrap form-wrap-validation'>
                    <input className='form-input' id='forms-news-email' type='email' name='email'
                           placeholder='name@domainname.com' required onChange={this.handleChange} />
                  </div>
                  <div className='button-wrap text-center'>
                    <button className='button button-primary button-xs' type='submit'>SUBMIT</button>
                  </div>
                </form>
              </div>
            </div>
          </div> : <ThankYou />}
        <Snackbar icon={this.state.snackbarIcon} message={this.state.snackbarMessage}
                  active={this.state.snackbarActive} autoclose={this.state.snackbarAutoClose} />
      </section>
    )
  }
}

Subscription.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string,
  backgroundImage: PropTypes.string.isRequired,
}

Subscription.defaultProps = {
  lead: null,
}

export default Subscription