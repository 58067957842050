import React from 'react'
import ThankYou from '../thankYou/thankYou'
import Snackbar from '../snackbar/snackbar'
import Recaptcha from 'react-recaptcha'

const FORM_NAME = 'Business Partnership'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class RealEstatePartnerships extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackbarIcon: 'loading',
      snackbarMessage: 'Sending',
      snackbarActive: false,
      formSent: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendingForm() {
    this.setState({
      ...this.state,
      snackbarActive: true,
    })
  }

  formSent() {
    this.setState({
      ...this.state,
      snackbarIcon: 'ok',
      snackbarMessage: 'Successfully sent!',
    })
    setTimeout(() => {
      this.setState({
        ...this.state,
        snackbarActive: false,
      })
    }, 3000)

    this.setState({ ...this.state, formSent: true })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.sendingForm()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.formSent())
      .catch(error => alert(error))
  }

  render() {
    return (
      <section className={'well well-sm'}>
        {!this.state.formSent ?
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-8 inset-md-min'>
                <form className='rd-mailform1 text-left' data-form-output='form-output-global'
                      data-form-type='contact' method='post' data-netlify='true' data-netlify-honeypot='bot-field'
                      onSubmit={this.handleSubmit} name={FORM_NAME}>
                  <p hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name='bot-field' onChange={this.handleChange} />
                    </label>
                  </p>
                  <div className='row row-20 align-items-end'>
                    <div className='col-md-6'>
                      <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                        <label className='form-label-outside' htmlFor='forms-name-captha'>FIRST AND LAST
                          NAME</label>
                        <input className='form-input' id='forms-name-captha' type='text' name='name'
                               placeholder='Your First & Last Name' required onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                        <label className='form-label-outside' htmlFor='forms-email-captcha'>COMPANY
                          NAME</label>
                        <input className='form-input' id='forms-company-captha' type='text'
                               name='company'
                               placeholder='Your Company Name' required onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                        <label className='form-label-outside'
                               htmlFor='forms-email-captcha'>E-MAIL</label>
                        <input className='form-input' id='forms-email-captcha' type='email' name='email'
                               placeholder='example@domain.com' required onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                        <label className='form-label-outside' htmlFor='forms-message-captcha'>YOUR
                          MESSAGE</label>
                        <textarea className='form-input' id='forms-message-captcha' name='message'
                                  placeholder='Write your message here'
                                  required onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                      <div className='form-wrap form-wrap-recaptcha'>

                      </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                      <button className='button button-primary button-xs button-block form-el-offset-1'
                              type='submit'>SUBMIT
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> : <ThankYou />
        }
        <Snackbar icon={this.state.snackbarIcon} message={this.state.snackbarMessage}
                  active={this.state.snackbarActive} autoclose={this.state.snackbarAutoClose} />
      </section>
    )
  }
}

export default RealEstatePartnerships