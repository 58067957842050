import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import './style.css'

class Member extends React.Component {
  render() {
    return (
      (this.props.variant === 'top') ?
        <div className='member-headshot caption margin-1'>
          <img src={this.props.headshotPhoto.file.url} alt='' /><br /><br />
          <h6><Link to={`/${this.props.page.slug}`}>{this.props.fullName}</Link></h6>
          <p className='small text-light-clr text-uppercase'>{this.props.position}</p>
        </div> :
        <div className='thumbnail thumbnail-3'>
          <img className='rounded-circle' src={this.props.headshotPhoto.file.url}
               width='200' height='200' alt='' />
          <div className='caption margin-1'>
            <h6><Link to={`/${this.props.page.slug}`}>{this.props.fullName}</Link></h6>
            <p className='small text-light-clr text-uppercase'>{this.props.position}</p>
          </div>
        </div>
    )
  }
}

Member.propTypes = {
  fullName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  headshotPhoto: PropTypes.object,
  page: PropTypes.object,
  variant: PropTypes.oneOf(['top', 'secondary']).isRequired,
}

export default Member