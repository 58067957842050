import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

import './well.css'

class Well extends React.Component {
    renderVariant = () => {
        switch (this.props.variant) {
            default:
                return (
                    <div className='row justify-content-center'>
                        <div className='col-xl-10 col-xl-offset-1'>
                            {(this.props.title && !this.props.hideTitle) ?
                                <h1 className='font-weight-bold'>{this.props.title}</h1> : ''}
                            {(this.props.lead) ? <div className='lead big'>{this.props.lead}</div> : ''}
                            {(this.props.image) ?
                                <img className='margin-2' src={`${this.props.image.src}??w=1200&fm=webp&q=80`}
                                     alt={this.props.image.alt}/> : ''}
                            {(this.props.cta) ?
                                <p className='lead big'><Link className='button button-primary'
                                      to={this.props.cta.link}>{this.props.cta.label}</Link></p> : ''}
                        </div>
                    </div>
                )
            case 'two-columns':
                return (
                    <div className='row'>
                        <div className='col-lg-6 col-xl-5'>
                            {(this.props.title) ? <h1 className='font-weight-bold'>{this.props.title}</h1> : ''}
                            {(this.props.lead) ? <div className='lead'>{this.props.lead}</div> : ''}
                            {(this.props.cta) ?
                                <p className={'lead'}>
                                    <Link className='button button-primary'
                                          to={this.props.cta.link}>{this.props.cta.label}</Link>
                                </p> : ''}
                        </div>
                        <div className='col-lg-6 col-xl-6 col-xl-offset-1 text-left'>
                            <img src={`${this.props.image.src}??w=600&fm=webp&q=80`} alt={this.props.image.alt}/>
                        </div>
                    </div>
                )
        }
    }

    render() {
        return (
            <section className={`well well-sm relative ${this.props.extraClass}`} style={this.props.style}>
                <div className='container'>
                    {this.renderVariant()}
                </div>
            </section>
        )
    }
}

Well.propTypes = {
    title: PropTypes.string,
    lead: PropTypes.any,
    image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }),
    style: PropTypes.object,
    extraClass: PropTypes.string,
    cta: PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    }),
    variant: PropTypes.oneOf(['default', 'two-columns']),
    hideTitle: PropTypes.bool
}

Well.defaultProps = {
    variant: 'default',
    hideTitle: false
}

export default Well