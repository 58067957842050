import React from "react";

const SNACKBAR_ICONS = {
    loading: 'fa-circle-o-notch fa-spin',
    ok: 'fa-check'
}

class Snackbar extends React.Component {
    get isActive() {
        return this.props.active ? 'active' : ''
    }

    get icon() {
        return SNACKBAR_ICONS[this.props.icon]
    }

    render() {
        return <div className={`snackbars ${this.isActive}`} id={'form-output-global'}>
            <p>
                <span className={`icon icon-xxs fa ${this.icon}`}/>
                <span>{this.props.message}</span>
            </p>
        </div>
    }
}

export default Snackbar