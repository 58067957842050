import React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import './style.css'

class ProjectEligibility extends React.Component {
  renderDescriptionImages() {
    return this.props.description.references.map(image => {
      return <figure>
        <img key={image.id} alt={image.title} src={image.file.url} />
      </figure>
    })
  }

  render() {
    return <section id={'eligibility'} className={'project-section project-eligibility'}>
      <div className={'container'}>
        <h2 className='project-headline'>{this.props.title}</h2>
        {renderRichText(this.props.description)}
        {this.renderDescriptionImages()}
      </div>
    </section>
  }
}

ProjectEligibility.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
}

export default ProjectEligibility