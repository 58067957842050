import React from 'react'
import PropTypes from 'prop-types'
import './requestmoreInfo.css'
import Snackbar from '../snackbar/snackbar'
import Recaptcha from 'react-recaptcha'
import ThankYou from '../thankYou/thankYou'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const FORM_NAME = 'Request More Information'

class RequestMoreInfoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackbarIcon: 'loading',
      snackbarMessage: 'Sending',
      snackbarActive: false,
      formSent: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendingForm() {
    this.setState({
      ...this.state,
      snackbarActive: true,
    })
  }

  formSent() {
    this.setState({
      ...this.state,
      snackbarIcon: 'ok',
      snackbarMessage: 'Successfully sent!',
    })
    setTimeout(() => {
      this.setState({
        ...this.state,
        snackbarActive: false,
      })
    }, 3000)

    this.setState({ ...this.state, formSent: true })
  }

  captchaValid() {
    return localStorage.getItem('recaptcha') >= 1
  }

  handleSubmit = e => {
    e.preventDefault()
    this.sendingForm()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'project': this.props.projectName,
        ...this.state,
      }),
    })
      .then(() => this.formSent())
      .catch(error => alert(error))
  }

  render() {
    return (
      <section id={'registration'} className={'project-section project-request-more-info'}>
        {!this.state.formSent ?
          <fieldset>
            <legend>Request More Information</legend>
            <form className='rd-mailform text-left' data-form-output='form-output-global' data-form-type='forms'
                  method='post' data-netlify='true' data-netlify-honeypot='bot-field'
                  onSubmit={this.handleSubmit} name={FORM_NAME}>
              <p hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name='bot-field' onChange={this.handleChange} />
                </label>
              </p>
              <div className='row row-20 align-items-end'>
                <div className='col-md-12'>
                  <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                    <label className='form-label-outside' htmlFor='forms-name'>FIRST AND LAST
                      NAME</label>
                    <input onChange={this.handleChange} className='form-input' id='forms-name'
                           type='text' name='name'
                           placeholder='Your First & Last Name' required />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                    <label className='form-label-outside' htmlFor='forms-phone'>PHONE NUMBER</label>
                    <input onChange={this.handleChange} className='form-input' id='forms-phone'
                           type='tel' name='phone'
                           placeholder='Your Phone Number' required />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                    <label className='form-label-outside' htmlFor='forms-email'>EMAIL ADDRESS</label>
                    <input onChange={this.handleChange} className='form-input' id='forms-email'
                           type='email' name='email'
                           placeholder='Your Email Address' required />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                    <label className='form-label-outside' htmlFor='forms-household'>ANNUAL HOUSEHOLD
                      INCOME</label>
                    <input onChange={this.handleChange} className='form-input' id='forms-household'
                           type='text' name='household'
                           placeholder='Your Annual Household Income' required />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-wrap'>
                    <label className='form-label-outside'>ARE YOU ALREADY WORKING WITH A LENDER?</label>
                    <div className='form-check'>
                      <input onChange={this.handleChange} className='form-check-input' type='radio'
                             name='lender' id='lender1'
                             value='yes' checked />
                      <label className='form-check-label' htmlFor='lender1'> Yes </label>
                    </div>
                    <div className='form-check'>
                      <input onChange={this.handleChange} className='form-check-input' type='radio'
                             name='lender' id='lender2'
                             value='no' />
                      <label className='form-check-label' htmlFor='lender2'> No </label>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-wrap'>
                    <label className='form-label-outside'>WILL YOU NEED HOME BUYER CREDIT
                      REPAIR?</label>
                    <div className='form-check'>
                      <input onChange={this.handleChange} className='form-check-input' type='radio'
                             name='credit-repair'
                             id='credit-repair1'
                             value='yes' checked />
                      <label className='form-check-label' htmlFor='credit-repair1'>Yes</label>
                    </div>
                    <div className='form-check'>
                      <input onChange={this.handleChange} className='form-check-input' type='radio'
                             name='credit-repair'
                             id='credit-repair2'
                             value='no' />
                      <label className='form-check-label' htmlFor='credit-repair2'>No</label>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-lg-8'>
                  <div className='form-wrap form-wrap-recaptcha'>

                    {/*<div className="recaptcha" id="captcha1" data-netlify-recaptcha="true"
                                         data-sitekey="6LfZlSETAAAAAC5VW4R4tQP8Am_to4bM3dddxkEt"/>*/}
                  </div>
                </div>
                <div className='col-md-12 col-lg-4'>
                  <button className='button button-primary button-xs' type='submit'
                          style={{ width: '100%' }}>SUBMIT
                  </button>
                </div>
              </div>
              <input type='hidden' name='form-name' value={FORM_NAME} />
              <input type={'hidden'} name={'project'} value={this.props.projectName} />
            </form>
          </fieldset> : <ThankYou />
        }
        <Snackbar icon={this.state.snackbarIcon} message={this.state.snackbarMessage}
                  active={this.state.snackbarActive} autoclose={this.state.snackbarAutoClose} />
      </section>
    )
  }
}

RequestMoreInfoForm.propTypes = {
  projectName: PropTypes.string.isRequired,
}

export default RequestMoreInfoForm