import React from 'react'
import PropTypes from 'prop-types'
import './counter.css'

class Counter extends React.Component {
  render() {
    return (
      <div className={this.props.extraClass}>
        <div className='counter' data-from={this.props.from} data-to={this.props.to} />
        <p className='counter-title font-secondary text-uppercase'>{this.props.title}</p>
      </div>
    )
  }
}

Counter.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  title: PropTypes.string,
  extraClass: PropTypes.string,
}

Counter.defaultProps = {
  from: 0,
  extraClass: 'col-6 col-md-6 col-lg-3',
}

export default Counter