import React from "react";
import PropTypes from "prop-types";

import './style.css'

class Quote extends React.Component {
    render() {
        return (
            <section className="quote text-center text-lg-left margin-1 bg-red">
                <div className="container">
                    <div className="row">
                        <div className="col-12 section-border">
                            <article className="well">
                                <h4 className="text-white text-center">{this.props.quote.quote}</h4>
                                <h5 className="lead text-white text-center">{this.props.author}</h5>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

Quote.propTypes = {
    author: PropTypes.string.isRequired,
    quote: PropTypes.object.isRequired
}

export default Quote