import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import './style.css'

class Swiper extends React.Component {
  renderImages() {
    if (this.props.backgroundImages) {
      return this.props.backgroundImages.map(backgroundImage => {
        return <div key={backgroundImage.id} className='swiper-slide' data-slide-bg={`${backgroundImage.file.url}?w=1920&h=1200&fm=webp&q=80`} />
      })
    }

    return <div className='swiper-slide' />
  }

  render() {
    return (
      <section className={`hero-size-${this.props.size.toLowerCase()} hero-color-${this.props.color.toLowerCase()}`}>
        <div className='swiper-container swiper-slider' data-autoplay='5000' data-slide-effect='fade'
             data-loop='false'>
          <div className='jumbotron text-center'>
            {(this.props.title) ? <h1>{this.props.title}</h1> : null}
            {(this.props.shortLead) ? <p className='big'>{this.props.shortLead.shortLead}</p> : null}
            <div className={'button-group-variant'}>
              {(this.props.ctaLabel) ?
                <Link className='button button-primary'
                      to={this.props.ctaLink}>{this.props.ctaLabel}</Link>
                : null}

              {(this.props.ctaSecondaryLabel) ?
                <Link className='button button-secondary'
                      to={this.props.ctaSecondaryLink}>{this.props.ctaSecondaryLabel}</Link>
                : null}
            </div>
          </div>
          <div className='swiper-wrapper'>
            {this.renderImages()}
          </div>
        </div>
      </section>
    )
  }
}

Swiper.propTypes = {
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaSecondaryLabel: PropTypes.string,
  ctaSecondaryLink: PropTypes.string,
  title: PropTypes.string,
  shortLead: PropTypes.object,
  backgroundImages: PropTypes.array,
  size: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
}

export default Swiper