import React from 'react'
import PropTypes from 'prop-types'
import {renderRichText} from "gatsby-source-contentful/rich-text";

import './style.css'

class ProjectLenders extends React.Component {
    renderLenders() {
        return this.props.lenders.map(lender => {
            return <div key={lender.id} className={'lender-block'}>
                <img className={'lender-block-logo'} src={lender.logo.file.url} alt={lender.logo.name}/>
                <div className={'lender-block-data'}>
                    <div className={'lender-block-name'}>{lender.contactName}</div>
                    <div className={'lender-block-email'}>{lender.contactEmail}</div>
                    <div className={'lender-block-phone'}>{lender.contactPhoneNumber}</div>
                </div>
            </div>
        })
    }

    render() {
        console.log(this.props)
        return (
            <section id={'resources'} className={'project-section project-resources'}>
                <div className={'container'}>
                    <h2 className="project-headline">{this.props.title}</h2>
                    <div className={'project-resources-description'}>
                        {renderRichText(this.props.description)}
                    </div>
                    <div className={'project-resources-lenders'}>
                        {this.renderLenders()}
                    </div>
                </div>
            </section>
        )
    }
}

ProjectLenders.propTypes = {
    title: PropTypes.string,
    description: PropTypes.object,
    lenders: PropTypes.array
}

export default ProjectLenders