import React from 'react'
import PropTypes from 'prop-types'
import RequestMoreInfo from './requestMoreInfo'
import BusinessPartnerships from './businessPartnerships'
import RealEstatePartnerships from './realEstatePartnerships'
import Share from './share'

class Form extends React.Component {
  render() {
    switch (this.props.form) {
      case 'Business Partnerships':
        return <BusinessPartnerships />
      case 'Real Estate Partnerships':
        return <RealEstatePartnerships />
      case 'Share Your Story':
        return <Share />
      case 'Request More Info':
        return <RequestMoreInfo projectName={this.props.projectName} />
      default:
        return null
    }
  }
}

Form.propTypes = {
  form: PropTypes.string.isRequired,
  projectName: PropTypes.string,
}

export default Form