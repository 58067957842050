import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Header from '../components/header/header'
import { withPrefix } from 'gatsby-link'
import '../components/variables.css'
import '../components/style.css'
import Footer from '../components/footer/footer'
import Well from '../components/well/well'
import CounterPanel from '../components/counterPanel/counterPanel'
import Subscription from '../components/subscription/subscription'
import { mapGraphQLToComponent } from '../helper'
import TabsPanel from '../components/tabsPanel/tabsPanel'
import Quote from '../components/quote/quote'
import Leadership from '../components/leadership/leadership'
import CardsGrid from '../components/cardsGrid/cardsGrid'
import Form from '../components/form/form'
import Swiper from '../components/swiper/swiper'
import Project from '../components/project/project'
import MemberBio from '../components/memberBio/memberBio'

class GeneralLayout extends React.Component {
  get renderSections() {
    if (this.props.sections) {
      return this.props.sections.map((section) => {
        if (section.internal) {
          switch (section.internal.type) {
            case 'ContentfulRichText':
              return <Well key={section.id} {...mapGraphQLToComponent('well', section)} />
            case 'ContentfulCounterPanel':
              return <CounterPanel key={section.id} {...mapGraphQLToComponent('counter', section)} />
            case 'ContentfulSubscriptionForm':
              return <Subscription key={section.id} {...mapGraphQLToComponent('subscription', section)} />
            case 'ContentfulTabsPanel':
              return <TabsPanel key={section.id} {...section} />
            case 'ContentfulQuote':
              return <Quote key={section.id} {...section} />
            case 'ContentfulLeadership':
              return <Leadership key={section.id} {...section} />
            case 'ContentfulCardsGrid':
              return <CardsGrid key={section.id} {...section} />
            case 'ContentfulForm':
              return <Form key={section.id} {...section} />
            case 'ContentfulProject':
              return <Project key={section.id} {...section} projectName={section.name} />
            case 'ContentfulMemberBio':
              return <MemberBio key={section.id} {...section} />
            case 'ContentfulPageHeader':
              return null
            default:
              console.log('Missing: ', section.internal.type)
              return null
          }
        }
      })
    }

    return null
  }

  get renderHero() {
    let response = null
    this.props.sections.forEach(section => {
      if (section.internal && section.internal.type === 'ContentfulPageHeader') {
        response = <Swiper key={section.id} {...section} />
      }
    })
    return response
  }

  render() {
    return (
      <div className={'page ' + this.props.extraClass}>
        <Helmet>
          <title>{`${this.props.pageTitle} | East to West`}</title>
          <link rel='stylesheet' href={withPrefix('/css/bootstrap.css')} />
          <link rel='stylesheet' href={withPrefix('/css/style.css')} />
          <link rel='icon' type='image/x-icon' href='/favicon.ico' />
        </Helmet>
        <Header>
          {this.renderHero}
        </Header>
        <main className={'page-content ' + this.props.extraClass}>
          {this.props.children}
          {this.renderSections}
        </main>
        <Footer />
      </div>
    )
  }
}

GeneralLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object),
  extraClass: PropTypes.string,
}

GeneralLayout.defaultProps = {
  extraClass: '',
}

export default GeneralLayout