import React from 'react'
import PropTypes from 'prop-types'
import {renderRichText} from 'gatsby-source-contentful/rich-text'

import './style.css'

class ProjectAbout extends React.Component {
    render() {
        return (
            <section id={'about'} className={'project-section project-about'}>
                <div className={'container'}>
                    <h2 className={'project-headline'}>{this.props.title}</h2>
                    <div className={'project-about-details'}>
                        <div>
                            {renderRichText(this.props.description)}
                        </div>
                        <img title={this.props.title} src={this.props.googleMap.file.url}/>
                    </div>
                </div>
            </section>
        )
    }
}

ProjectAbout.propTypes = {
    title: PropTypes.string,
    description: PropTypes.object,
    googleMap: PropTypes.object,
}

export default ProjectAbout