import React from 'react'
import './style.css'

class ThankYou extends React.Component {
  render() {
    return <div className={'thank-you-block'}>
      <h2>Thank you!</h2>
      <p>Your request has been submitted and we’ll be in touch soon.</p>
    </div>
  }
}

export default ThankYou