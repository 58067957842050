import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

class ProjectParticipation extends React.Component {
    renderSteps() {
        return this.props.steps.map(step => {
            return <div key={step.id} className={'project-participation-step'}>
                <div className={'project-participation-step-round'}>
                    <span>{step.number}</span>
                    {step.title}
                </div>
                <div className={'project-participation-step-details'}>
                    {step.details}
                </div>
            </div>
        })
    }

    render() {
        return (
            <section id={'participation'} className={'project-section project-participation'}>
                <div className={'container'}>
                    <h2 className="project-headline">{this.props.title}</h2>
                    <div className={'project-participation-steps'}>
                        {this.renderSteps()}
                    </div>
                    <div className={'project-participation-footer'}>
                        Ready to begin?
                        <a href={'#registration'} className={'button button-primary'}>Request more info</a>
                    </div>
                </div>
            </section>
        )
    }
}

ProjectParticipation.propTypes = {
    title: PropTypes.string,
    steps: PropTypes.array
}

export default ProjectParticipation