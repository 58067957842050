import React from 'react'
import Counter from './counter/counter'
import PropTypes from 'prop-types'

class CounterPanel extends React.Component {
    get counterList() {
        return this.props.items.map((counterItem) => {
                return <Counter key={counterItem.id} from={counterItem.from} to={counterItem.to}
                                title={counterItem.title}/>
            }
        );
    }

    render() {
        return (
            <section className='text-center'>
                <div className='container counter-panel'>
                    <div className='row'>
                        {this.counterList}
                    </div>
                </div>
            </section>
        )
    }
}

CounterPanel.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object)
}

export default CounterPanel