import React from 'react'
import PropTypes from 'prop-types'
import {renderRichText} from "gatsby-source-contentful/rich-text";

import './style.css'

class ProjectInvestments extends React.Component {
    renderDescriptionImages(description) {
        return description.references.map(image => {
            return <img key={image.id} alt={image.title} src={image.file.url}/>
        })
    }

    currency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        }).format(number);
    }

    renderInvestments() {
        return this.props.investments.map(investment => {
            console.log(investment)
            return <div className="card" key={investment.id}>
                <div className="card-header" id={'head-' + investment.id}>
                    <h5 className="mb-0">
                        <button className="btn btn-link" data-toggle="collapse"
                                data-target={'#collapse-' + investment.id}
                                aria-expanded="false" aria-controls={'collapse-' + investment.id}>
                            <span>
                                {investment.title}
                            </span>
                        </button>
                    </h5>
                </div>

                <div id={'collapse-' + investment.id} className="collapse"
                     aria-labelledby={'head-' + investment.id}
                     data-parent="#accordion">
                    <div className="card-body">
                        <div className={'project-city-investments-facts'}>
                            <dl className={'fields-grid'}>
                                {(investment.estimatedCost > 0) ?
                                    <>
                                        <dt className={'fields-grid-field'}>Estimated Cost</dt>
                                        <dd className={'fields-grid-field-value'}>{this.currency(investment.estimatedCost)}</dd>
                                    </> :
                                    null
                                }
                                {(investment.fpuaPartnership > 0) ?
                                    <>
                                        <dt className={'fields-grid-field'}>FPUA Partnership</dt>
                                        <dd className={'fields-grid-field-value'}>{this.currency(investment.fpuaPartnership)}</dd>
                                    </> :
                                    null
                                }
                                {(investment.estimatedFunding) ?
                                    <>
                                        <dt className={'fields-grid-field'}>Estimated Funding</dt>
                                        <dd className={'fields-grid-field-value'}>{investment.estimatedFunding}</dd>
                                    </> :
                                    null
                                }
                                {(investment.projectCost > 0) ?
                                    <>
                                        <dt className={'fields-grid-field'}>Project Cost</dt>
                                        <dd className={'fields-grid-field-value'}>{this.currency(investment.projectCost)}</dd>
                                    </> :
                                    null
                                }
                            </dl>
                        </div>
                        <div className={'description'}>
                            {renderRichText(investment.description)}
                        </div>
                        {this.renderDescriptionImages(investment.description)}
                    </div>
                </div>
            </div>
        });
    }

    render() {
        console.log(this.props)
        return (
            <section id={'city-investments'} className={'project-section project-city-investments'}>
                <div className={'container'}>
                    <h2 className="project-headline">{this.props.title}</h2>
                    <div className={'project-city-investments-description'}>
                        {renderRichText(this.props.description)}
                    </div>
                    <div id={'accordion'} className={'project-city-investments-accordion'}>
                        {this.renderInvestments()}
                    </div>
                </div>
            </section>
        )
    }
}

ProjectInvestments.propTypes = {
    title: PropTypes.string,
    investments: PropTypes.array
}

export default ProjectInvestments